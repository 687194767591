/** @jsx jsx */
import { jsx } from 'theme-ui';

import React, { useState, useEffect } from 'react';
import { Flex, Box, Label, Text } from 'theme-ui';
import { Link } from 'gatsby';
import equals from 'ramda/src/equals';
import { Trans } from '@lingui/macro';

import { useCurrentVariantContext } from './CurrentVariantContext';
import HairColorVariantImage from '../../components/HairColorVariantImage';

const ProductVariantSelector = (props) => {
  const { variants, options } = props;
  const { setCurrentVariant } = useCurrentVariantContext();
  const [userSelectedOptions, setUserSelectedOptions] = useState(() => {
    let initialState;
    options.forEach((option) => {
      initialState = { ...initialState, [option.name]: option.values[0] };
    });
    return initialState;
  });

  const showVariants =
    options.length > 0 &&
    !(
      options.length === 1 &&
      options[0].name === 'Title' &&
      options[0].values.length === 1 &&
      options[0].values[0] === 'Default Title'
    );

  const handleOptionsSelect = (name, value) => {
    setUserSelectedOptions((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const getVariantFromSelectedOptions = (options, variants) => {
    let variantSelectedOptions;
    let availableForSale;
    let shopifyId = false;
    let price;
    let compareAtPrice;
    let sku;

    variants.forEach((v) => {
      v.selectedOptions.forEach((o) => {
        variantSelectedOptions = {
          ...variantSelectedOptions,
          [o.name]: o.value,
        };
      });
      if (equals(variantSelectedOptions, options)) {
        shopifyId = v.shopifyId;
        availableForSale = v.availableForSale;
        price = v.price;
        compareAtPrice = v.compareAtPrice;
        sku = v.sku;
      }
    });
    return { shopifyId, availableForSale, price, compareAtPrice, sku };
  };

  useEffect(() => {
    setCurrentVariant(
      getVariantFromSelectedOptions(userSelectedOptions, variants)
    );
  }, [userSelectedOptions, setCurrentVariant, variants]);

  return (
    <>
      {showVariants ? (
        <Flex {...props} sx={{ width: '100%', flexDirection: 'column' }}>
          {options.map((option, index) => {
            return (
              <Flex
                my={3}
                mx={1}
                sx={{ width: '100%', flexWrap: 'wrap' }}
                key={`box-${option.name}-${index}`}
              >
                <Label htmlFor={option.name} mb={1}>
                  {option.name !== 'Size' && option.name !== 'Color' ? (
                    <Box>{option.name}</Box>
                  ) : (
                    ''
                  )}

                  {option.name === 'Size' ? (
                    <>
                      <Box>
                        <Trans id="ProductVariantSelector.SizeLabel">
                          Size
                        </Trans>
                      </Box>
                      <Box ml={1} sx={{ position: 'relative', bottom: '2px' }}>
                        <Text sx={{ fontSize: 1 }}>(</Text>
                        <Text
                          as={Link}
                          to="/guides/how-to-choose-hair-size"
                          sx={{ fontSize: 1 }}
                          variant="link"
                        >
                          <Trans id="ProductVariantSelector.SizeHowTo">
                            How to choose size?
                          </Trans>
                        </Text>
                        <Text sx={{ fontSize: 1 }}>)</Text>
                      </Box>
                    </>
                  ) : (
                    ''
                  )}

                  {option.name === 'Color' ? (
                    <>
                      <Box>
                        <Trans id="ProductVariantSelector.ColorLabel">
                          Color
                        </Trans>
                      </Box>
                      <Box ml={1} sx={{ position: 'relative', bottom: '2px' }}>
                        <Text sx={{ fontSize: 1 }}>(</Text>
                        <Text
                          as={Link}
                          to="/guides/how-to-choose-hair-color"
                          sx={{ fontSize: 1 }}
                          variant="link"
                        >
                          <Trans id="ProductVariantSelector.ColorHowTo">
                            How to choose color?
                          </Trans>
                        </Text>
                        <Text sx={{ fontSize: 1 }}>)</Text>
                      </Box>
                    </>
                  ) : (
                    ''
                  )}
                </Label>
                {/* <Select
                  onChange={(event) => {
                    handleOptionsSelect(option.name, event.target.value);
                  }}
                  id={option.name}
                  name={option.name}
                  key={`select-${option.name}-${index}`}
                >
                  {option.values.map((value, index) => (
                    <option
                      key={`option-${option.name}-${index}`}
                      value={value}
                    >
                      {value}
                    </option>
                  ))}
                </Select> */}

                {option.values.map((value, index) => (
                  <Label sx={{ width: 'auto' }} key={index}>
                    <input
                      type="radio"
                      value={value}
                      name={option.name}
                      onChange={(event) => {
                        handleOptionsSelect(option.name, event.target.value);
                      }}
                      defaultChecked={index === 0 ? true : false}
                      sx={{
                        position: 'absolute',
                        opacity: 0,
                        width: 0,
                        height: 0,
                        '& + div > img': {
                          cursor: 'pointer',
                        },
                        '& + span': {
                          cursor: 'pointer',
                        },
                        '&:checked + div > img': {
                          border: '2px solid',
                          borderColor: 'secondary',
                        },
                        '&:checked + div > span': {
                          color: 'primary',
                          fontWeight: 'bold',
                        },
                        '&:checked + span': {
                          border: '2px solid',
                          borderColor: 'secondary',
                        },
                      }}
                    />
                    {option.name === 'Color' ? (
                      <HairColorVariantImage colorName={value} />
                    ) : (
                      <Box
                        as="span"
                        px={2}
                        py={1}
                        mx={1}
                        sx={{ border: '1px solid', borderColor: 'black' }}
                      >
                        {value}
                      </Box>
                    )}
                  </Label>
                ))}
              </Flex>
            );
          })}
        </Flex>
      ) : (
        ''
      )}
    </>
  );
};

export default ProductVariantSelector;
