/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Box, Text, Image } from 'theme-ui';

const HairColorVariantImage = ({ colorName = '1' }) => (
  <Box m={1} sx={{ textAlign: 'center', width: [60, 100] }}>
    <Image
      width="100"
      height="100"
      alt={colorName}
      title={colorName}
      sx={{ borderRadius: 99999, width: [60, 100] }}
      src={`/haircolors/${colorName}.webp`}
    />

    <Text sx={{ fontSize: [1, 2] }}>{colorName}</Text>
  </Box>
);

export default HairColorVariantImage;
