/** @jsx jsx */
import { jsx } from 'theme-ui';

import React, { useState } from 'react';
import { Flex, Box, Text, Heading } from 'theme-ui';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import { CarouselProvider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import strings from './strings.json';
import substrDescription from '../../utils/substrDescription';
import ProductCounter from '../../components/ProductCounter';
import Divider from '../../components/Divider';
import Breadcrumbs from '../../components/Breadcrumbs';
import ProductDescriptionFeatures from '../../components/ProductDescriptionFeatures';

import ProductGalleryThumbnails from './ProductGalleryThumbnails';
import ProductGalleryCarousel from './ProductGalleryCarousel';
import { CurrentVariantContextProvider } from './CurrentVariantContext';
import ProductVariantSelector from './ProductVariantSelector';
import ProductVariantAddToCart from './ProductVariantAddToCart';
import ProductVariantPrice from './ProductVariantPrice';
import ProductVariantSku from './ProductVariantSku';
import ProductDescription from './ProductDescription';
import ProductReviews from './ProductReviews';

// react-payment-icons-inline heavily increases webpack bundle size. Need to find alternative solution. Will disable it for now.
// const Payments = loadable(() => import('../../components/Payments'));

// const ShareButtons = loadable(() => import('../../components/ShareButtons'));
// const DescriptionBox = loadable(() => import('./DescriptionBox'));
import ShareButtons from '../../components/ShareButtons';
import DescriptionBox from './DescriptionBox';

function ProductPage({ data, pageContext, location }) {
  const [currentAmount, setCurrentAmount] = useState(1);
  const { locale } = pageContext;

  const {
    product: {
      title,
      handle,
      description,
      images,
      variants,
      options,
      vendor,
      productType,
      cmsConnection,
      // reviewsConnection: reviews,
      fields: { shortDescription, withoutShortDescription },
    },
    reviews: { nodes: reviewsData },
  } = data;

  const reviews = reviewsData.map((r) => {
    r.title = r.translation[locale].title;
    r.content = r.translation[locale].content;
    r.name = r.translation[locale].name;
    return r;
  });

  const cmsData = cmsConnection || {};

  const {
    // shortDescription,
    // descriptionHtml: withoutShortDescription,
    descriptionSections: sections,
  } = cmsData;

  // There are cases when product doesn't belong to any collection.
  // In this case we need to set a guard in case "collection" and "fields" props undefined.
  const { collection } = data || {};
  const { fields } = collection || {};
  const { title: collectionTitle } = collection || {
    title: null,
  };
  const { shopifyThemePath: collectionPath } = fields || {
    shopifyThemePath: null,
  };

  const { cartUrl } = pageContext;
  const {
    shareButtons,
    productImagesCarouselProps,
    reviewsNumberPerPage,
  } = data.store.siteMetadata.gatsbyStorefrontConfig;

  function increaseAmount() {
    setCurrentAmount((a) => a + 1);
  }

  function decreaseAmount() {
    setCurrentAmount((a) => (a <= 1 ? 1 : a - 1));
  }

  return (
    <>
      <CurrentVariantContextProvider>
        <Helmet>
          {/* Google's meta description length is up to 920 pixels, which might
          allow for up to 158 characters. On mobile devices, the max limit is
          about 680 pixels and 120 characters. Oct 1, 2019 */}
          <meta
            name="description"
            content={
              shortDescription
                ? substrDescription(shortDescription, 158)
                : substrDescription(description, 158)
            }
          />
        </Helmet>

        <Flex
          my={3}
          py={[2, 3, 4]}
          sx={{
            width: '100%',
            bg: '#f4f1eb',
            justifyContent: 'center',
          }}
        >
          <Box mx={3}>
            <Breadcrumbs
              productTitle={title}
              collectionTitle={collectionTitle}
              collectionPath={collectionPath}
              separator="/"
            />
          </Box>
        </Flex>

        <CarouselProvider
          naturalSlideWidth={
            productImagesCarouselProps.naturalSlideWidth
              ? productImagesCarouselProps.naturalSlideWidth
              : images[0]
              ? images[0].localFile.childImageSharp.main.width
              : 1
          }
          naturalSlideHeight={
            productImagesCarouselProps.naturalSlideHeight
              ? productImagesCarouselProps.naturalSlideHeight
              : images[0]
              ? images[0].localFile.childImageSharp.main.height
              : 1
          }
          totalSlides={
            images.filter(
              (image) => image.altText !== 'exclude_from_product_images'
            ).length
          }
        >
          <Flex
            px={2}
            mx="auto"
            sx={{ flexDirection: ['column', null, 'row'], maxWidth: 1300 }}
          >
            {images && images.length > 1 ? (
              <Box
                sx={{
                  width: ['100%', null, 65],
                  order: [2, null, 1],
                }}
                py={2}
                px={[2, null, 0]}
              >
                <ProductGalleryThumbnails
                  images={images}
                  title={title}
                  maxContainerHeight={600}
                  maxImageHeight={63}
                  maxImageWidth={63}
                />
              </Box>
            ) : (
              ''
            )}
            <Box
              sx={{ width: ['100%', null, '55%'], order: [1, null, 2] }}
              ml="auto"
              py={2}
              pr={images && images.length > 1 ? [2, null, 3] : [2, null, 3]}
              pl={images && images.length > 1 ? [2, null, 3] : [2, null, 0]}
              data-product-image-container
            >
              <ProductGalleryCarousel
                images={images}
                title={title}
                maxContainerHeight={
                  productImagesCarouselProps.naturalSlideHeight
                }
              />
            </Box>

            <Flex
              sx={{
                width: ['100%', null, '45%'],
                flexDirection: 'column',
                order: 3,
              }}
              px={[2, null, 3]}
              data-product-info
            >
              <Box>
                <Heading
                  as="h1"
                  mb={3}
                  sx={{ fontSize: [4, 5, 6] }}
                  data-title-box
                >
                  {title}
                </Heading>

                {shortDescription ? (
                  <DescriptionBox
                    source={shortDescription}
                    escapeHtml={false}
                    mb={3}
                  />
                ) : (
                  ''
                )}
              </Box>

              {/* <ProductVariantSelector
                variants={variants}
                options={options}
                pageContext={pageContext}
                mb={4}
              /> */}

              {/* <Flex sx={{ alignItems: 'center' }} mb={4}>
                <Box mr={2}>
                  <Text>{productQuantityLabel}</Text>
                </Box>
                <Box sx={{ width: '20%' }}>
                  <ProductCounter
                    decreaseAmount={decreaseAmount}
                    increaseAmount={increaseAmount}
                    currentAmount={currentAmount}
                  />
                </Box>
              </Flex> */}

              {/* <Flex mb={4}>
                <Box>
                  <ProductVariantAddToCart
                    amount={currentAmount}
                    cartUrl={cartUrl}
                  />
                </Box>
              </Flex> */}

              {/* <Divider bg="grey" mb={4} /> */}

              {/* <ProductVariantSku /> */}

              {/* <Flex mb={4} sx={{ alignItems: 'center' }}>
              <Box mr={2}>
                <Text>{shareButtonsLabel}</Text>
              </Box>
              <Box>
                <ShareButtons buttons={shareButtons} location={location.href} />
              </Box>
            </Flex> */}
            </Flex>
          </Flex>
          <Flex pt={3} px={4} mx="auto" sx={{ maxWidth: 1300 }}>
            <Box sx={{ width: '100%' }}>
              <ProductVariantSelector
                variants={variants}
                options={options}
                pageContext={pageContext}
                mb={4}
              />

              <ProductVariantPrice
                initialDisplayPrice={variants[0].price}
                mb={3}
              />

              <Box mb={4}>
                <ProductVariantAddToCart
                  amount={currentAmount}
                  cartUrl={cartUrl}
                />
              </Box>

              {/* <Divider bg="grey" mb={4} /> */}

              {/* <ProductDescription
                description={withoutShortDescription || description}
                sections={sections}
              /> */}
            </Box>
          </Flex>

          <Box py={[4, 5]} px={3}>
            <ProductDescriptionFeatures />
          </Box>

          {reviews && reviews.length > 0 ? (
            <ProductReviews
              reviews={reviews}
              reviewsNumber={reviews.length}
              paginationNum={reviewsNumberPerPage}
            />
          ) : (
            ''
          )}
        </CarouselProvider>
      </CurrentVariantContextProvider>
    </>
  );
}

export default ProductPage;
