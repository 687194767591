/** @jsx jsx */
import { jsx } from 'theme-ui';

import React, { useState, useEffect } from 'react';
import { Button } from 'theme-ui';
import { navigate } from 'gatsby';
import { Trans } from '@lingui/macro';

import { useShopifyFunctions } from '../../hooks/useShopifyFunctions';
import { useCurrentVariantContext } from './CurrentVariantContext';

const ProductVariantAddToCart = ({ amount, cartUrl }) => {
  const { addItem } = useShopifyFunctions();
  const { currentVariant } = useCurrentVariantContext();
  const [disabled, setDisabled] = useState(false);

  async function addToCartHandler(id, amount) {
    await addItem({ variantId: id, quantity: amount });
    navigate(cartUrl);
  }

  useEffect(() => {
    if (currentVariant && currentVariant.hasOwnProperty('availableForSale')) {
      currentVariant.availableForSale ? setDisabled(false) : setDisabled(true);
    }
  }, [currentVariant]);

  return (
    <Button
      disabled={disabled}
      ml="auto"
      sx={{ width: ['100%'] }}
      onClick={() => {
        addToCartHandler(currentVariant.shopifyId, amount);
      }}
      variant={!disabled ? 'primary' : 'disabled'}
    >
      {!disabled ? (
        <Trans id="ProductVariantAddToCart.AddToCartLabel">Add to cart</Trans>
      ) : (
        <Trans id="ProductVariantAddToCart.SoldoutLabel">Soldout</Trans>
      )}
    </Button>
  );
};

export default ProductVariantAddToCart;
