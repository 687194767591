/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex, Box, Text, Heading } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, t } from '@lingui/macro';

const ProductDescriptionFeatures = () => {
  return (
    <Flex sx={{ flexDirection: 'column', maxWidth: 950 }} mx={'auto'}>
      <Flex
        mb={[2, 4]}
        sx={{
          flexWrap: ['wrap'],
        }}
      >
        <Box pr={[0, 4]} sx={{ width: ['100%', 475] }}>
          <Heading as="h3" mb={[1, 2]}>
            <Trans id="ProductDescriptionFeatures.Feature1_title">
              Innovative Monotapes
            </Trans>
          </Heading>
          <Trans id="ProductDescriptionFeatures.Feature1_description">
            <Text as="p" mb={2}>
              Our unique technique allows making monotapes hair extensions well
              filled with hair.
            </Text>
            <Text as="p" mb={2}>
              Having from 70 to 90 grams of hair (depending on the length) in
              just 4 monotapes brings remarkable volume with only one Kisscurls
              package.
            </Text>
            <Text as="p" mb={2}>
              Just 4 Kisscurls monotapes equal up to about 40 pieces of classic
              tape-ins.
            </Text>
          </Trans>
        </Box>
        <Box sx={{ width: ['100%', 475] }}>
          <StaticImage
            src="../../../../../src/images/features/optimized/innovative_monotapes.jpg"
            alt={t({
              id: `ProductDescriptionFeatures.Feature1_ImgAlt`,
              message: `Innovative Monotapes`,
            })}
            placeholder="blurred"
            layout="constrained"
          />
        </Box>
      </Flex>

      <Flex
        mb={[2, 4]}
        sx={{
          flexWrap: ['wrap-reverse', 'wrap'],
        }}
      >
        <Box pr={[0, 4]} sx={{ width: ['100%', 475] }}>
          <StaticImage
            src="../../../../../src/images/features/optimized/invisible.jpg"
            alt={t({
              id: `ProductDescriptionFeatures.Feature7_ImgAlt`,
              message: `Invisible and imperceptible`,
            })}
            placeholder="blurred"
            layout="constrained"
          />
        </Box>

        <Box sx={{ width: ['100%', 475] }}>
          <Heading as="h3" mb={[1, 2]}>
            <Trans id="ProductDescriptionFeatures.Feature7_title">
              Invisible and imperceptible
            </Trans>
          </Heading>
          <Text>
            <Trans id="ProductDescriptionFeatures.Feature7_description">
              <Text as="p" mb={2}>
                Kisscurls hair extensions are thin, flexible, and utterly
                imperceptible when you touch your hair.
              </Text>
              <Text as="p" mb={2}>
                Installed between the layers of your own hair and being only 1
                millimeter thick and 10 millimeters in height, hair tapes are
                entirely invisible on your hair and comfortable to wear.
              </Text>
              <Text as="p" mb={2}>
                Even with a strong wind or under a bright sun, no one will guess
                you are wearing hair extension tapes.
              </Text>
            </Trans>
          </Text>
        </Box>
      </Flex>

      <Flex
        mb={[2, 4]}
        sx={{
          flexWrap: ['wrap'],
        }}
      >
        <Box pr={[0, 4]} sx={{ width: ['100%', 475] }}>
          <Heading as="h3" mb={[1, 2]}>
            <Trans id="ProductDescriptionFeatures.Feature3_title">
              Easy to self-install
            </Trans>
          </Heading>
          <Text>
            <Trans id="ProductDescriptionFeatures.Feature3_description">
              Kisscurls monotapes are very easy to install and remove at home.
              Our uniquely flexible and durable monotapes are possible to
              self-install in just about 20 minutes. Compared to traditional
              tape-ins (up to 40 pieces), that may be installed only in
              professional hair salons.
            </Trans>
          </Text>
        </Box>

        <Box sx={{ width: ['100%', 475] }}>
          <StaticImage
            src="../../../../../src/images/features/optimized/easy-to-self-install.jpg"
            alt={t({
              id: `ProductDescriptionFeatures.Feature3_ImgAlt`,
              message: `Easy to self-install`,
            })}
            placeholder="blurred"
            layout="constrained"
          />
        </Box>
      </Flex>

      <Flex
        mb={[2, 4]}
        sx={{
          flexWrap: ['wrap-reverse', 'wrap'],
        }}
      >
        <Box pr={[0, 4]} sx={{ width: ['100%', 475] }}>
          <StaticImage
            src="../../../../../src/images/features/optimized/everyday_wearing.jpg"
            alt={t({
              id: `ProductDescriptionFeatures.Feature4_ImgAlt`,
              message: `For Everyday Wearing`,
            })}
            placeholder="blurred"
            layout="constrained"
          />
        </Box>

        <Box sx={{ width: ['100%', 475] }}>
          <Heading as="h3" mb={[1, 2]}>
            <Trans id="ProductDescriptionFeatures.Feature4_title">
              For Everyday Wearing
            </Trans>
          </Heading>
          <Text>
            <Trans id="ProductDescriptionFeatures.Feature4_description">
              Kisscurls monotapes are designed for everyday wearing comparing to
              products made for occasional events. You may safely sleep with
              monotapes, wash style, and cut just like your own hair without
              re-installments for up to 8 weeks.
            </Trans>
          </Text>
        </Box>
      </Flex>

      <Flex
        mb={[2, 4]}
        sx={{
          flexWrap: ['wrap'],
        }}
      >
        <Box pr={[0, 4]} sx={{ width: ['100%', 475] }}>
          <Heading as="h3" mb={[1, 2]}>
            <Trans id="ProductDescriptionFeatures.Feature5_title">
              Reusable
            </Trans>
          </Heading>
          <Text>
            <Trans id="ProductDescriptionFeatures.Feature5_description">
              Kisscurls monotapes are re-installable, and usually, each
              application will hold for 6-8 weeks as your hair grows. Monotapes
              may be used more than 10 times with proper care. Reusable multiple
              times with special hair replacement tape that comes in the
              package.
            </Trans>
          </Text>
        </Box>

        <Box sx={{ width: ['100%', 475] }}>
          <StaticImage
            src="../../../../../src/images/features/optimized/reusable.jpg"
            alt={t({
              id: `ProductDescriptionFeatures.Feature5_ImgAlt`,
              message: `Reusable`,
            })}
            placeholder="blurred"
            layout="constrained"
          />
        </Box>
      </Flex>

      <Flex
        mb={[2, 4]}
        sx={{
          flexWrap: ['wrap-reverse', 'wrap'],
        }}
      >
        <Box pr={[0, 4]} sx={{ width: ['100%', 475] }}>
          <StaticImage
            src="../../../../../src/images/features/optimized/high_quality_human_hair.jpg"
            alt={t({
              id: `ProductDescriptionFeatures.Feature2_ImgAlt`,
              message: `High-quality human hair`,
            })}
            placeholder="blurred"
            layout="constrained"
          />
        </Box>

        <Box sx={{ width: ['100%', 475] }}>
          <Heading as="h3" mb={[1, 2]}>
            <Trans id="ProductDescriptionFeatures.Feature2_title">
              High-quality human hair
            </Trans>
          </Heading>
          <Text>
            <Trans id="ProductDescriptionFeatures.Feature2_description">
              Kisscurls monotapes are made of carefully chosen healthiest virgin
              100% real human hair from Europe. Professionally colored using a
              unique gentle technique for natural-looking, silky hair texture.
              The hair will last up to 1.5 years with proper care. Our hair can
              be styled with hot tools. No silicones on hair.
            </Trans>
          </Text>
        </Box>
      </Flex>

      <Flex
        mb={[2, 4]}
        sx={{
          flexWrap: ['wrap'],
        }}
      >
        <Box pr={[0, 4]} sx={{ width: ['100%', 475] }}>
          <Heading as="h3" mb={[1, 2]}>
            <Trans id="ProductDescriptionFeatures.Feature6_title">
              Safe for your hair
            </Trans>
          </Heading>
          <Trans id="ProductDescriptionFeatures.Feature6_description">
            <Text as="p" mb={2}>
              Kisscurls monotapes extensions are safe for your hair.
            </Text>
            <Text as="p" mb={2}>
              The installation process requires NO special tools, NO heat, NO
              chemicals, or glues.
            </Text>
            <Text as="p" mb={2}>
              Our replacement tape adhesive is hypo-allergic and waterproof
              while easy to remove and leaves zero residues. The home removal
              process is clean, quick, and non-damaging with our special remover
              spray.
            </Text>
          </Trans>
        </Box>
        <Box sx={{ width: ['100%', 475] }}>
          <StaticImage
            src="../../../../../src/images/features/optimized/safe_for_hair.jpg"
            alt={t({
              id: `ProductDescriptionFeatures.Feature6_ImgAlt`,
              message: `Safe for your hair`,
            })}
            placeholder="blurred"
            layout="constrained"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default ProductDescriptionFeatures;
